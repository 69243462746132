@use 'mixins' as *;

.video-block {
  display: block;

  > div > div {
    aspect-ratio: 16 / 9;
  }

  video {
    align-self: center;
    max-inline-size: 100%;
    max-block-size: calc(100vh - var(--header-height));
    object-fit: cover;
    inline-size: 100%;
  }

  .description {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-block-start: var(--spacing-small);
    font-size: var(--font-size-body12);

    .video-label {
      color: var(--color-gray3);
      margin-inline-end: var(--spacing-medium);
    }

    .video-title {
      line-height: var(--line-height-body12);
      margin-block-start: 1px;
    }
  }

  :hover {
    div > button {
      opacity: 1;
      pointer-events: initial;
      transition: opacity 0.3s ease;
    }
  }
}

.wrapper {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.play-button {
  --transition-duration: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  inline-size: 3.5rem;
  block-size: 3.5rem;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  transition: opacity var(--transition-duration) ease;
  cursor: pointer;
  background: rgb(234 234 234 / 50%);
  overflow: hidden;
  border-radius: 100%;
  border: thin solid var(--color-hell-turquoise);

  svg path {
    opacity: 1;
    fill: var(--color-hell-turquoise);
  }

  @include breakpoint-min(medium) {
    &::before {
      content: '';
      position: absolute;
      block-size: 100%;
      inline-size: 100%;
      inset-block-start: 0;
      inset-inline-start: 0;
      background: var(--color-black-hover);
      transform: translateX(-100%);
      pointer-events: none;
      z-index: -1;
      transition: all var(--transition-duration) ease;
    }

    &:hover::before {
      transform: translateX(0);
    }
  }

  @include breakpoint-min(small) {
    inline-size: 3.5rem;
    block-size: 3.5rem;
  }
}

.mux-player {
  --dialog: none; /* Hide the error dialog */
  --media-object-fit: cover;
  --controls: none;

  align-self: center;
  max-width: 100%;
  max-height: calc(100vh - var(--header-height));
  object-fit: cover;
  width: 100%;
}
